import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "When the Latest Tech Makes you Feel Dumb",
  "date": "2016-10-24",
  "description": "You're not the problem",
  "cover": "/img/cover/saddrawing.jpg",
  "pic": "/img/saddrawing.jpg",
  "color": "#83caa7"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`It's Saturday afternoon and you've blocked out a few hours of free time to finally learn that hot new thing that's supposed to make the life of a developer `}<em parentName="p">{`pure bliss`}</em>{`. You settle in with a snack, a drink, and a full laptop battery. But a few minutes into it something happens you weren't expecting: you feel `}<strong parentName="p">{`dumb`}</strong>{`. Dumb as a bucket.`}</p>
    <p>{`Not that you'd ever admit it to anyone, especially not to your friends at the office who are raving about this thing. So you power through it, read more of the docs, even follow along the coding examples, just waiting for that sweet, sweet `}<strong parentName="p">{`click`}</strong>{` of enlightenment. But it doesn't come. You just feel dumb and frustrated. You start thinking that tech maybe just sucks anyway and why can't people learn how to keep things simple. Before you know it, your pleasant afternoon ends in a rage tweet you later regret (and quietly delete).`}</p>
    <p>{`What tech recently made you feel this way? I'm guessing your mind jumped straight to that out-of-control `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{`. Or that time you grokked Redux and were feeling pretty great about it until you encountered the rabbit hole of other things you now supposedly need with it. This frustrating feeling happens to every single one of us.`}</p>
    <p>{`So what does this mean? Are you a "dinosaur", or some kind of "maintenance developer", or actually dumb? Nope. Nope. Nope. `}<strong parentName="p">{`When tech makes you feel dumb, that just means it is solving a problem you don't currently have.`}</strong></p>
    <p>{`The second you start feeling dumb, stop what you're doing and write down the answer to this question:`}</p>
    <blockquote>
      <p parentName="blockquote">{`What is the one main problem this tech solves?`}</p>
    </blockquote>
    <p>{`If you can't answer that, there's a good chance you need to get better at `}<a parentName="p" {...{
        "href": "/post/what-not-to-learn"
      }}>{`deciding what not to learn`}</a>{`. If you `}<strong parentName="p">{`can`}</strong>{` clearly state the problem – and your interest is genuine – you need to `}<strong parentName="p">{`give yourself the problem`}</strong>{`. This means `}<em parentName="p">{`shelving`}</em>{` the new tech for a bit, and making do without it. Work on parts of your project that could potentially develop said problem. Use the things that the new tech replaces, and see if you experience the pain. Keep the problem in the back of your mind as you go about your work. One of two things will happen:`}</p>
    <h4>{`You never actually experience the problem.`}</h4>
    <p><em parentName="p">{`setState`}</em>{` or `}<em parentName="p">{`mutable`}</em>{` data structures or `}<em parentName="p">{`external`}</em>{` stylesheets or whatever are working really well for you. Hey! You just spared yourself some feel bads and a few hours wasted on something you don't actually need. OR...`}</p>
    <h4>{`You eventually do encounter the problem.`}</h4>
    <p><strong parentName="p">{`Now`}</strong>{` you go learn about the thing that wants to solve it. This time you're ready. You have a real (as opposed to hypothetical) problem you'd like solved. This time, you won't feel dumb.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      